import React from 'react'
import { graphql } from 'gatsby'
import FundBase from './fund-base.js'

const FundTemplate = ({ data, pageContext }) => {
  var currentFund = data.fund.previousOutcomePeriods.filter(f => f.outcomePeriodSubId === pageContext.previousOutcomePeriodSubId)[0];
  return <FundBase fund={currentFund} previousOutcomePeriods={data.fund.previousOutcomePeriods || []} />
}

export const query = graphql`
  query($fundId: String!, $previousOutcomePeriodSubId: String!) {
    fund(
      fundId: { eq: $fundId }
      previousOutcomePeriods: { elemMatch: { outcomePeriodSubId: { eq: $previousOutcomePeriodSubId } } }
    ) {
      fundId
      strategySeries
      strategyName
      strategyDescription
      outcomePeriodSubId
      resetMonthLabel
      refAsset
      refAsset2
      frequency
      downside
      upside
      startingUpsideRate
      startingDownsideRate
      downsideRateOffset
      outcomePeriodDays
      cusip
      inceptionDate
      startDate
      endDate
      initialStats {
        date
        fundNav
        fundReturn
        equity
        fixedIncomeContribution
        referenceAssetReturn
        secondaryReferenceAssetReturn
        strategyZone
        secondaryStrategyZone
        remainingTimeValueCurrent
        remainingTimeValueNet
        remainingUpsideCurrent
        remainingUpsideNet
        remainingUpsideBeforeCurrent
        remainingUpsideBeforeNet
        remainingDownsideBeforeCurrent
        remainingDownsideBeforeNet
        remainingDownsideCurrent
        remainingDownsideNet
        outcomePeriodDaysRemaining
        targetNetEquityOptionAllocation
      }
      latestStats {
        date
        fundNav
        fundReturn
        equity
        fixedIncomeContribution
        referenceAssetReturn
        secondaryReferenceAssetReturn
        strategyZone
        secondaryStrategyZone
        remainingTimeValueCurrent
        remainingTimeValueNet
        remainingUpsideCurrent
        remainingUpsideNet
        remainingUpsideBeforeCurrent
        remainingUpsideBeforeNet
        remainingDownsideBeforeCurrent
        remainingDownsideBeforeNet
        remainingDownsideCurrent
        remainingDownsideNet
        outcomePeriodDaysRemaining
        targetNetEquityOptionAllocation
      }
      stats {
        date
        fundNav
        fundReturn
        equity
        fixedIncomeContribution
        referenceAssetReturn
        secondaryReferenceAssetReturn
        strategyZone
        secondaryStrategyZone
        remainingTimeValueCurrent
        remainingTimeValueNet
        remainingUpsideCurrent
        remainingUpsideNet
        remainingUpsideBeforeCurrent
        remainingUpsideBeforeNet
        remainingDownsideBeforeCurrent
        remainingDownsideBeforeNet
        remainingDownsideCurrent
        remainingDownsideNet
        outcomePeriodDaysRemaining
        targetNetEquityOptionAllocation
      }
      resourceLinks {
        label
        link
      }
      facts {
        date
        fund {
          returnsYTD
          returns1Y
          returns3Y
          returns5Y
          returns10Y
          returnsInception
          beta
          volatility
          drawdown
          sharpeRatio
        }
        primaryIndex {
          returnsYTD
          returns1Y
          returns3Y
          returns5Y
          returns10Y
          returnsInception
          beta
          volatility
          drawdown
          sharpeRatio
        }
        secondaryIndex {
          returnsYTD
          returns1Y
          returns3Y
          returns5Y
          returns10Y
          returnsInception
          beta
          volatility
          drawdown
          sharpeRatio
        }
        spx {
          returnsYTD
          returns1Y
          returns3Y
          returns5Y
          returns10Y
          returnsInception
          beta
          volatility
          drawdown
          sharpeRatio
        }
        bloombergUSAgg {
          returnsYTD
          returns1Y
          returns3Y
          returns5Y
          returns10Y
          returnsInception
          beta
          volatility
          drawdown
          sharpeRatio
        }
      }
      previousOutcomePeriods {
        fundId
        strategySeries
        strategyName
        strategyDescription
        outcomePeriodSubId
        resetMonthLabel
        refAsset
        refAsset2
        frequency
        downside
        upside
        startingUpsideRate
        startingDownsideRate
        downsideRateOffset
        outcomePeriodDays
        cusip
        inceptionDate
        startDate
        endDate
        initialStats {
          date
          fundNav
          fundReturn
          equity
          fixedIncomeContribution
          referenceAssetReturn
          secondaryReferenceAssetReturn
          strategyZone
          secondaryStrategyZone
          remainingTimeValueCurrent
          remainingTimeValueNet
          remainingUpsideCurrent
          remainingUpsideNet
          remainingUpsideBeforeCurrent
          remainingUpsideBeforeNet
          remainingDownsideBeforeCurrent
          remainingDownsideBeforeNet
          remainingDownsideCurrent
          remainingDownsideNet
          outcomePeriodDaysRemaining
          targetNetEquityOptionAllocation
        }
        latestStats {
          date
          fundNav
          fundReturn
          equity
          fixedIncomeContribution
          referenceAssetReturn
          secondaryReferenceAssetReturn
          strategyZone
          secondaryStrategyZone
          remainingTimeValueCurrent
          remainingTimeValueNet
          remainingUpsideCurrent
          remainingUpsideNet
          remainingUpsideBeforeCurrent
          remainingUpsideBeforeNet
          remainingDownsideBeforeCurrent
          remainingDownsideBeforeNet
          remainingDownsideCurrent
          remainingDownsideNet
          outcomePeriodDaysRemaining
          targetNetEquityOptionAllocation
        }
        stats {
          date
          fundNav
          fundReturn
          equity
          fixedIncomeContribution
          referenceAssetReturn
          secondaryReferenceAssetReturn
          strategyZone
          secondaryStrategyZone
          remainingTimeValueCurrent
          remainingTimeValueNet
          remainingUpsideCurrent
          remainingUpsideNet
          remainingUpsideBeforeCurrent
          remainingUpsideBeforeNet
          remainingDownsideBeforeCurrent
          remainingDownsideBeforeNet
          remainingDownsideCurrent
          remainingDownsideNet
          outcomePeriodDaysRemaining
          targetNetEquityOptionAllocation
        }
        resourceLinks {
          label
          link
        }
        facts {
          date
          fund {
            returnsYTD
            returns1Y
            returns3Y
            returns5Y
            returns10Y
            returnsInception
            beta
            volatility
            drawdown
            sharpeRatio
          }
          primaryIndex {
            returnsYTD
            returns1Y
            returns3Y
            returns5Y
            returns10Y
            returnsInception
            beta
            volatility
            drawdown
            sharpeRatio
          }
          secondaryIndex {
            returnsYTD
            returns1Y
            returns3Y
            returns5Y
            returns10Y
            returnsInception
            beta
            volatility
            drawdown
            sharpeRatio
          }
          bloombergUSAgg {
            returnsYTD
            returns1Y
            returns3Y
            returns5Y
            returns10Y
            returnsInception
            beta
            volatility
            drawdown
            sharpeRatio
          }
        }
      }
    }
  }
`

export default FundTemplate
